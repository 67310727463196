// StatExplanation.js
import React from 'react';
import { Box, Typography, Paper, Chip, Divider } from '@mui/material';
import './Glossary.css';

const StatExplanation = ({ name, meaning, formula, pages }) => {
    return (
        <Paper elevation={1} sx={{ p: 3, mb: 3, borderRadius: 2 }} className="stat-explanation-paper">
            <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 'bold', color: '#38292C' }}>
                {name}
            </Typography>

            <Divider sx={{ mb: 2 }} />

            <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                    意味:
                </Typography>
                <Typography variant="body1" sx={{ ml: 2 }}>
                    {meaning}
                </Typography>
            </Box>

            {formula && (
                <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                        計算式:
                    </Typography>
                    <Box
                        sx={{
                            ml: 2,
                            p: 1.5,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 1,
                            fontFamily: 'monospace',
                            fontSize: '0.95rem'
                        }}
                        className="stat-formula"
                    >
                        {formula}
                    </Box>
                </Box>
            )}

            <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                    表示ページ:
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, ml: 2 }}>
                    {pages.map((page, index) => (
                        <Chip
                            key={index}
                            label={page}
                            size="small"
                            sx={{
                                backgroundColor: '#65FBD2',
                                color: '#38292C',
                                fontWeight: 'medium'
                            }}
                        />
                    ))}
                </Box>
            </Box>
        </Paper>
    );
};

export default StatExplanation;