// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './components/NavBar';
import EnhancedHeroSection from './components/EnhancedHeroSection';
import ImpactMetricsTable from './components/ImpactMetricsTable';
import Footer from './components/Footer';
import TeamStatsTable from './components/TeamStatsTable';
import PlayerOnOffTable from './components/PlayerOnOffTable';
import PlayerAdvancedTable from './components/PlayerAdvancedTable';
import PlayerShotQuality from './components/PlayerShotQuality';
import TeamShotQuality from './components/TeamShotQuality';
import WOWYPage from './components/WOWYPage';
import GPMTable from './components/GPMTable';
import GPMExplanation from './components/GPMExplanation';
import GPMExplanationEnglish from './components/GPMExplanationEnglish';
import PlayerProfilePage from './components/PlayerProfilePage';
import GlossaryPage from './components/GlossaryPage';
import NotFound from './components/NotFound';
import { AnimatePresence } from 'framer-motion';

//import './App.css'; // Ensure this file imports Tailwind CSS

const AnimatedRoutes = () => {
    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<EnhancedHeroSection />} />
                <Route path="/impactmetrics/" element={<ImpactMetricsTable />} />
                <Route path="/teamstats/" element={<TeamStatsTable />} />
                <Route path="/player-onoff/" element={<PlayerOnOffTable />} />
                <Route path="/player-advanced/" element={<PlayerAdvancedTable />} />
                <Route path="/player-shooting/" element={<PlayerShotQuality />} />
                <Route path="/team-shooting/" element={<TeamShotQuality />} />
                <Route path="/wowy/" element={<WOWYPage />} />
                <Route path="/gpm/" element={<GPMTable />} />
                <Route path="/introducing-gpm/" element={<GPMExplanation />} />
                <Route path="/introducing-gpm/en/" element={<GPMExplanationEnglish />} />
                <Route path="/player-profile/:playerId" element={<PlayerProfilePage />} />
                <Route path="/glossary/" element={<GlossaryPage />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </AnimatePresence>
    );
};

const App = () => {
    return (
        <Router>
            <div className="flex flex-col min-h-screen bg-white text-gray-900">
                <NavBar />
                <div className="flex-grow">
                    <AnimatedRoutes />
                </div>
                <Footer />
            </div>
        </Router>
    );
};

export default App;