// GlossaryPage.js
import React, { useState } from 'react';
import { Container, Typography, Box, Tabs, Tab, Divider, Paper } from '@mui/material';
import StatExplanation from './StatExplanation';
import { statsData, availablePages } from './GlossaryData';
import './Glossary.css';

const GlossaryPage = () => {
  const [selectedPage, setSelectedPage] = useState('All');
  
  const handlePageChange = (event, newValue) => {
    setSelectedPage(newValue);
  };
  
  // Filter stats based on selected page
  const filteredStats = selectedPage === 'All' 
    ? statsData 
    : statsData.filter(stat => stat.pages.includes(selectedPage));

  return (
    <Container maxWidth="lg" sx={{ my: 5 }}>
      <Typography variant="h4" component="h1" gutterBottom className="glossary-title">
        Glossary
      </Typography>
      
      <Typography variant="body1" paragraph sx={{ mb: 4 }}>
        impactmetrics.jpで使用されている各種指標・スタッツの説明です。
      </Typography>
      
      <Paper elevation={2} sx={{ mb: 4, overflow: 'hidden' }}>
        <Tabs 
          value={selectedPage} 
          onChange={handlePageChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            '& .MuiTab-root': {
              color: '#ABABAB',
              fontWeight: 'normal',
              minWidth: 'auto',
              px: 2,
              borderRadius: '4px',
              mx: 0.5,
              my: 0.5,
              transition: 'all 0.2s ease-in-out'
            },
            '& .Mui-selected': {
              color: '#38292C !important', // Black text for selected tab
              fontWeight: 'bold',
              backgroundColor: '#65FBD2', // Green background for selected tab
            },
            '& .MuiTabs-indicator': {
              display: 'none' // Hide the indicator line since we're using background color
            }
          }}
        >
          {availablePages.map((page) => (
            <Tab 
              key={page} 
              value={page} 
              label={page} 
              sx={{ 
                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                py: { xs: 1, sm: 1.5 }
              }}
            />
          ))}
        </Tabs>
      </Paper>
      
      <Divider sx={{ mb: 4 }} />
      
      {filteredStats.map((stat, index) => (
        <StatExplanation
          key={index}
          name={stat.name}
          meaning={stat.meaning}
          formula={stat.formula}
          pages={stat.pages}
        />
      ))}
      
      {filteredStats.length === 0 && (
        <Paper elevation={1} sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="body1">
            該当する統計指標は見つかりませんでした。
          </Typography>
        </Paper>
      )}
    </Container>
  );
};

export default GlossaryPage;