import React, { useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, Box, Typography, ThemeProvider, createTheme } from '@mui/material';
import { styled } from '@mui/system';
import gsap from 'gsap';

// Custom theme based on the provided color palette
const theme = createTheme({
  palette: {
    background: {
      default: '#FAF8FF', // white
      hover: '#38292C', // black for hover
    },
    text: {
      primary: '#38292C', // black
      secondary: '#ABABAB', // grey
    },
    primary: {
      main: '#65FBD2', // green
    },
  },
});

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: 280,
    background: theme.palette.background.default,
  },
}));

const MenuLink = styled(ListItem)(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.background.hover,
    color: theme.palette.primary.main,
  },
  padding: theme.spacing(1, 3),
  transition: 'background-color 0.3s, color 0.3s',
  '& .MuiListItemText-primary': {
    fontWeight: 'bold', // Make the font bold
  },
}));

const ContactLink = styled('a')(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.primary.main,
  },
  transition: 'color 0.3s',
}));

const SidebarNav = ({ menuState, setMenuState }) => {
  const sidebarRef = useRef(null);
  const menuTimeline = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    menuTimeline.current = gsap.timeline({ paused: true });
    menuTimeline.current.fromTo(
      sidebarRef.current,
      { x: '100%' },
      { duration: 0.75, x: '0%', ease: 'power3.inOut' }
    );
  }, []);

  useEffect(() => {
    menuState ? menuTimeline.current.play() : menuTimeline.current.reverse();
  }, [menuState]);

  const handleNavigate = (path) => {
    setMenuState(false);
    navigate(path);
  };

  const isActive = (path) => location.pathname === path;

  const menuItems = [
    { text: 'Home', path: '/' },
    { text: 'GPM', path: '/gpm' },
    { text: 'PIPM / BPM', path: '/impactmetrics' },
    { text: 'Player Advanced', path: '/player-advanced' },
    { text: 'On/Off Four Factors', path: '/player-onoff' },
    { text: 'Player Shooting', path: '/player-shooting' },
    { text: 'WOWY Combinations', path: '/wowy' },
    { text: 'Team Stats', path: '/teamstats' },
    { text: 'Team Shooting', path: '/team-shooting' },
    { text: 'Glossary', path: '/glossary' },
  ];

  return (
    <ThemeProvider theme={theme}>
      <StyledDrawer
        anchor="right"
        open={menuState}
        onClose={() => setMenuState(false)}
        ref={sidebarRef}
      >
        <List>
          {menuItems.map((item) => (
            <MenuLink
              button
              key={item.text}
              onClick={() => handleNavigate(item.path)}
              active={isActive(item.path)}
            >
              <ListItemText primary={item.text} />
            </MenuLink>
          ))}
        </List>
        <Box sx={{ mt: 'auto', p: 2 }}>
          <Typography variant="subtitle2" gutterBottom color="text.secondary">
            Contact
          </Typography>
          <Box mb={1}>
            <Typography variant="body2">
              <ContactLink href="mailto:bimpactmetrics@gmail.com">
                bimpactmetrics@gmail.com
              </ContactLink>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">
              <ContactLink href="https://twitter.com/impactmetricsjp" target="_blank" rel="noopener noreferrer">
                X: @impactmetricsjp
              </ContactLink>
            </Typography>
          </Box>
        </Box>
      </StyledDrawer>
    </ThemeProvider>
  );
};

export default SidebarNav;