// GlossaryData.js

export const statsData = [
    {
        name: 'ORTG (Offensive Rating)',
        meaning: 'チームの100回の攻撃機会あたりの得点。チームのオフェンス効率を示す。選手レベルのページでは、選手が出場している時間のチームのパフォーマンスを示している。',
        formula: '100 * PTS / Poss',
        pages: ['Player Advanced', 'On/Off Four Factors', 'Team Stats']
    },
    {
        name: 'DRTG (Defensive Rating)',
        meaning: 'チームの100回の守備機会あたりの失点。チームのディフェンス効率を示す。選手レベルのページでは、選手が出場している時間のチームのパフォーマンスを示している。',
        formula: '100 * Opp PTS / Opp Poss',
        pages: ['Player Advanced', 'On/Off Four Factors', 'Team Stats']
    },
    {
        name: 'Net RTG (Net Rating)',
        meaning: 'チームの攻守100回あたりの得失点差。チームの総合的な効率を示す。選手レベルのページでは、選手が出場している時間のチームのパフォーマンスを示している。',
        formula: 'ORTG - DRTG',
        pages: ['Player Advanced', 'On/Off Four Factors', 'Team Stats']
    },
    {
        name: 'Pace',
        meaning: 'チームの40分毎の攻撃回数。チームがどのような展開を好むかが伺える。',
        formula: '40 * (Poss / Minutes) * 5',
        pages: ['Team Stats']
    },
    {
        name: 'eFG% (Effective Field Goal Percentage)',
        meaning: '3PTのシュートには1.5倍の重みをつけたフィールドゴールの効率。FG%からは見えない得点効率を見ようとしている。',
        formula: '(FGM + 0.5 * 3PM) / FGA',
        pages: ['Player Advanced', 'On/Off Four Factors', 'Team Stats']
    },
    {
        name: 'TOV% (Turnover Percentage)',
        meaning: 'ターンオーバーで終わったポゼッションの割合。チームがどれだけシュートでポゼッションを終えているかを示す。',
        formula: 'TOV / Poss',
        pages: ['On/Off Four Factors', 'Team Stats']
    },
    {
        name: 'OREB% (Offensive Rebound Percentage)',
        meaning: '全てのリバウンド機会のうち、OREBを獲得した割合。選手レベルのページでは、出場している時間のリバウンド機会のうち、選手個人が獲得したOREBの割合を示している。',
        formula: 'OREB / (OREB + Opp DREB)',
        pages: ['Player Advanced', 'On/Off Four Factors', 'Team Stats']
    },
    {
        name: 'FTr (Free Throw Rate)',
        meaning: 'フィールドゴールの試投数とフリースローの成功数の比率。チームがどれだけフリースローで得点を獲っているかの割合を示す。',
        formula: 'FTM / FGA',
        pages: ['On/Off Four Factors', 'Team Stats']
    },
    {
        name: 'USG% (Usage Rate)',
        meaning: '選手個人が、出場している時間のチームの攻撃を終わらせた割合。チームオフェンスの中での選手の役割の大きさを示す指標の一つ。',
        formula: '(FGA + FTA * 0.44 + TOV) / POSS',
        pages: ['Player Advanced']
    },
    {
        name: 'TS% (True Shooting Percentage)',
        meaning: '2PT、3PT、フリースローを含めたシュートの得点効率。eFG%とは違い、フリースローを含めた得点効率である。',
        formula: 'PTS / (2 * (FGA + FTA * 0.44))',
        pages: ['Player Advanced']
    },
    {
        name: '3PAr (Three-Point Attempt Rate)',
        meaning: '全フィールドゴールアテンプトのうち、3PTのアテンプトだったシュートの割合。選手がどのようなシュートを打っているかを示す指標の一つ。',
        formula: '3PA / FGA',
        pages: ['Player Advanced']
    },
    {
        name: 'FTAr (Free Throw Attempt Rate)',
        meaning: '全フィールドゴールアテンプトに対してフリースローをアテンプトした割合。選手がどれだけフリースローラインに立っているかを示す指標の一つ。',
        formula: 'FTA / FGA',
        pages: ['Player Advanced']
    },
    {
        name: 'AST/TOV (Assist-to-Turnover Ratio)',
        meaning: '選手個人のアシストとターンオーバーの比。ターンオーバーが生まれやすいアシストと比較することにより、選手がどれだけターンオーバーしているかを見る指標の一つ。',
        formula: 'AST / TOV',
        pages: ['Player Advanced']
    },
    {
        name: 'AST% (Assist Percentage)',
        meaning: '選手が出場している時間に、チームメイトのフィールドゴールをアシストした割合。選手がどれだけチームメイトのシュートを演出しているかを見る指標の一つ。',
        formula: 'AST / On Court Teammmate FGM',
        pages: ['Player Advanced']
    },
    {
        name: 'FREQ%　(Frequency)',
        meaning: '全フィールドゴールアテンプトのうち、該当のショットタイプをアテンプトした割合',
        formula: 'Shot Type FGA / All FGA',
        pages: ['Player Shooting', 'Team Shooting']
    },
    {
        name: 'xPTS (Expected Points)',
        meaning: 'リーグ平均の選手が同じエリアからシュートを打った際に獲得できていたであろう得点数。',
        pages: ['Player Shooting', 'Team Shooting']
    },
    {
        name: 'Points Added',
        meaning: '実際にとっていた得点数と、xPTSの差分。同じエリアからシュートを打ったリーグ平均の選手よりも、どれだけ得点を上乗せできていたか(もしくはできていなかったか)を示す。',
        formula: 'PTS - xPTS',
        pages: ['Player Shooting', 'Team Shooting']
    },
    {
        name: 'Shot Quality',
        meaning: 'リーグ平均の選手が同じエリアからシュートを打った際に記録していたであろうeFG%。選手/チームが打っているシュートのクオリティを示す指標の一つ。',
        pages: ['Player Shooting', 'Team Shooting']
    },
    {
        name: 'Shot Making',
        meaning: '実際に記録したeFG%と、Shot Qualityの差分。同じエリアからシュートを打ったリーグ平均の選手よりも、どれだけシュート効率を上乗せできていたか(もしくはできていなかったか)を示す。',
        formula: 'eFG% - Shot Quality',
        pages: ['Player Shooting', 'Team Shooting']
    },
    {
        name: 'Stable eFG%',
        meaning: '選手が現在打っているシュートの量・クオリティ・それぞれのエリアからの現在の確率を考慮したシーズン終了時点のeFG%の予想。シーズン序盤のシュート率が不安定な時に一つの参考となる指標。',
        pages: ['Player Shooting']
    },
    {
        name: 'O-PIPM',
        meaning: '選手がチームのORTGに与えるインパクトを示す総合的な指標の一つ。Jacob Goldsteinが考案したもので、ボックススコアとOn/Offスタッツを使用して計算される。',
        pages: ['PIPM/BPM']
    },
    {
        name: 'D-PIPM',
        meaning: '選手がチームのDRTGに与えるインパクトを示す総合的な指標の一つ。Jacob Goldsteinが考案したもので、ボックススコアとOn/Offスタッツを使用して計算される。',
        pages: ['PIPM/BPM']
    },
    {
        name: 'PIPM (Player Impact Plus-Minus)',
        meaning: '選手がチームのNet Ratingに与えるインパクトを示す総合的な指標の一つ。Jacob Goldsteinが考案したもので、ボックススコアとOn/Offスタッツを使用して計算される。',
        pages: ['PIPM/BPM']
    },
    {
        name: 'O-BPM',
        meaning: '選手がチームのORTGに与えるインパクトを示す総合的な指標の一つ。Daniel Myersが考案したもので、ボックススコアスタッツのみを使用して計算される。',
        pages: ['PIPM/BPM']
    },
    {
        name: 'D-BPM',
        meaning: '選手がチームのDRTGに与えるインパクトを示す総合的な指標の一つ。Daniel Myersが考案したもので、ボックススコアスタッツのみを使用して計算される。',
        pages: ['PIPM/BPM']
    },
    {
        name: 'BPM (Box Plus-Minus)',
        meaning: '選手がチームのNet Ratingに与えるインパクトを示す総合的な指標の一つ。Daniel Myersが考案したもので、ボックススコアスタッツのみを使用して計算される。',
        pages: ['PIPM/BPM']
    },
    {
        name: 'VORP (Value Over Replacement Player)',
        meaning: 'BPMと出場時間をベースとして計算される、Replacement PlayerがチームのNet Ratingに与える影響をどれだけ上回ったかを示す数値。',
        pages: ['PIPM/BPM']
    },
    {
        name: 'Stable USG%',
        meaning: '選手の各種ボックススコアスタッツを考慮したシーズン終了時点のUSG%の予想。シーズン序盤のサンプルが少ない時に一つの参考となる指標。',
        pages: ['GPM']
    },
    {
        name: 'Stable TS%',
        meaning: '選手の各種ボックススコアスタッツを考慮したシーズン終了時点のTS%の予想。シーズン序盤のサンプルが少ない時に一つの参考となる指標。',
        pages: ['GPM']
    },
    {
        name: 'O-GPM',
        meaning: '選手がチームのORTGに与えるインパクトを示す総合的な指標の一つ。当ウェブサイトが独自に考案したもので、ボックススコアとラインナップのデータを使用して計算される。',
        pages: ['GPM']
    },
    {
        name: 'D-GPM',
        meaning: '選手がチームのDRTGに与えるインパクトを示す総合的な指標の一つ。当ウェブサイトが独自に考案したもので、ボックススコアとラインナップのデータを使用して計算される。',
        pages: ['GPM']
    },
    {
        name: 'GPM (Guesstimated Plus-Minus)',
        meaning: '選手がチームのNet Ratingに与えるインパクトを示す総合的な指標の一つ。当ウェブサイトが独自に考案したもので、ボックススコアとラインナップのデータを使用して計算される。',
        pages: ['GPM']
    },
    {
        name: 'gWAR (Guesstimated Wins Above Replacement)',
        meaning: 'GPMと出場時間をベースとして計算される、Replacement Playerと比較して積み上げた勝ち星の数',
        pages: ['GPM']
    },
];

// Define the available pages for filtering
export const availablePages = [
    'All',
    'GPM',
    'PIPM/BPM',
    'Player Advanced',
    'On/Off Four Factors',
    'Player Shooting',
    'Team Stats',
    'Team Shooting'
];